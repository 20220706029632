<template>
  <a-table
    :row-selection="rowSelection"
    :columns="columns"
    :data-source="data"
    :row-key="record => record.id" 
    :pagination="pagination"
    :scroll="Yscroll"
    size="small"
  />
</template>
<script>



export default {
  name: 'EnePointTable',
  props: {
    title: {
      type: String,
      default: "信号点选择",
    },
    data: {
      type: Array,
      default: [],
    },
    selectedRowKeys: {
      type: Array,
      default: [],
    },
    fatherChangeMethod: {
        type: Function,
        default: null
    },
    checkType:{//多选/单选，checkbox or radio
       type: String,
       default: "checkbox",
    },
    Yscroll:{
       type: Object,
       default: { y: 200 },
    }
  }, 
  data() {
    return {
      columns:[

        {
          title: this.$t('energy.enedata298'),
          dataIndex: 'pointNo',
          width: 85,
          fixed: "left",
        },
        {
          title: this.$t('energy.enedata164'),
          dataIndex: 'pointName',
        },
        
      ],
      //selectedRowKeys:[],
      pagination:false,
    };
  },
  watch:{
    "data":{
      handler(val){
        //this.selectedRowKeys = [];
      },
      deep:true,
      immediate:true,
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:this.checkType,
      };
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      if(this.fatherChangeMethod){
          this.fatherChangeMethod(selectedRowKeys)
      }
      //this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>
