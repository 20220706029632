<template>
  <div id="enePointGR" ref="enePointGR">
    <a-modal v-model="visible" :title="title"  :width="600" :getContainer="() => this.$refs.enePointGR" :footer="null">
        <div v-loading="cloading" :element-loading-text="$t('alarm.a0')">
            <a-row>
                <a-col :span="8" style="height:400px;overflow:auto;border-right: 1px solid #e8e8e8;">
                    <!-- :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" -->
                        <!-- style="width: 240px" -->
                    <a-tree
                        :placeholder="$t('energy.enedata326')" @select="handleChange"
                        :tree-data="grList"
                        :selectedKeys="grId"
                        :replace-fields="{ title: 'text', key: 'no', children: 'children' }"
                        :default-expand-all="true"
                    >
                    
                </a-tree>
                </a-col>
                <a-col :span="16" style="">
                    <div class="mg-add">
                        <a-icon type="plus-circle" style="vertical-align: 0.3rem;" @click="show" :title="$t('energy.enedata325')"/>
                    </div>
                    <div v-show="isshow">
                        <a-form-model ref="enePointGRForm" 
                            :model="form" 
                            :rules="rules"
                            :label-col="labelCol" 
                            :wrapper-col="wrapperCol"  >
                                <a-form-model-item  ref="parentId" :label="$t('energy.enedata606')" v-if="form.id==0" >
                                        <a-tree-select
                                            v-model="form.parentId"
                                            style="width: 100%"
                                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                            :tree-data="grList"
                                            :replace-fields="{ label: 'text', value: 'no', children: 'children' }"
                                            placeholder="Please select"
                                            tree-default-expand-all
                                        >
                                        
                                        </a-tree-select>
                                </a-form-model-item>
                                <a-form-model-item  ref="name" prop="name">
                                    <span slot="label">
                                        {{$t('energy.enedata327')}}&nbsp;
                                        <a-tooltip :title='disallowed'>
                                        <a-icon type="question-circle-o" />
                                        </a-tooltip>
                                    </span>
                                    <a-input 
                                        v-model="form.name"
                                        @blur="
                                        () => {
                                            $refs.name.onFieldBlur();
                                        }
                                        "
                                        :placeholder="$t('energy.enedata328')"
                                    />
                                </a-form-model-item>
                                <a-form-model-item v-bind="tailFormItemLayout">
                                    <a-button type="primary" @click="onSubmit">
                                    {{$t('energy.enedata023')}}
                                    </a-button>
                                    <a-button type="danger" style="margin-left: 10px;" @click="delSubmit"  :disabled="deleable">
                                        {{$t('energy.enedata169')}}
                                    </a-button>
                                </a-form-model-item>
                        </a-form-model>
                    </div>
                </a-col>
            </a-row>
            <!-- <div class="info">
                

                 <a-tree-select
                    v-model="grId" :placeholder="$t('energy.enedata326')" @change="handleChange"
                    style="width: 240px"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="grList"
                    :replace-fields="{ label: 'text', value: 'no', children: 'children' }"
                    tree-default-expand-all
                >
                    
                </a-tree-select>

                
            </div> -->
            
        </div>
    </a-modal>
  </div>
</template>
<script>
import { getEnePointGrList ,saveEnePointGr,deleteEnePointGr} from "../../../api/energy";
export default {
  name: 'EnePointGrSetting',
  props: {
    title: {
      type: String,
      default: '点Gr设置',
    },
    data: {
      type: Object,
    },
  },
  data() {
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;

        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata329')));
        } else {
        callback();
        }
    };
    return {
        visible:false,
        cloading:false,
        grList:[],
        form:{id:0,name:'',parentId:0},
        grId:[],
        grName:'',
        enable: false,
        deleable:true,
        isshow:false,
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        },
        rules: {
            name: [
                { required: true, message: this.$t('energy.enedata328'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata330'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
    };
  },
  mounted(){
    
  },
  watch:{
    "$parent.grset.visible":{
      handler(val){
          if(val){              
               this.visible = this.$parent.grset.visible;
               this.cloading=false;
               if(this.$refs.enePointGRForm){
                    this.$refs.enePointGRForm.resetFields();
              }
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
        handler(val){
            if(!val){
                this.$parent.grset.visible =  this.visible;
                if(this.$refs.enePointGRForm){
                    this.$refs.enePointGRForm.resetFields();
                }
                this.form.name = '';
                this.form.parentId='';
                this.form.id = 0;
                this.deleable = true;
                this.isshow = false;
                this.cloading=false;
            }else{
                this.getEnePointGrDatas();
            }
        },
      deep:true,
      immediate:true,
    },
  },
  methods: {
        show(){
          
            if(!this.isshow){
              this.isshow = !this.isshow;
            }
            this.grId = [];
            this.form.name = '';
            this.form.parentId='';
            this.form.id = 0;
            this.deleable = true;
        },
        handleChange(value, obj){
            console.log("handleChange",value, obj);
            if(obj.selected){
                this.grId = value;
                this.isshow = true;
                let id = parseInt(value[0]);
                if(id === 0 ){
                    this.form.name = '';
                    this.form.parentId='';
                    this.form.id = 0;
                    this.deleable = true;
                }else{ 
                    this.form.parentId=0;
                    this.form.id = id;
                    this.form.name = obj.node.title;
                    this.deleable = false;
                }
            }else{
                this.grId = [];
                this.form.name = '';
                this.form.parentId='';
                this.form.id = 0;
                this.deleable = true;
                this.isshow = false;
            }
            
        },
        onSubmit(){
            this.$refs.enePointGRForm.validate(valid => {
                if (valid) {
                    this.$confirm({
                        title: this.$t('energy.enedata284'),
                        centered: true,
                        onOk: () => {
                            this.savaPointGr();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        delSubmit(){
            this.$confirm({
                title: this.$t('energy.enedata283'),//'确认要删除所选择的点Gr信息吗？',
                centered: true,
                onOk: () => {
                    this.deletePointGr();
                },
            });
        },
        getEnePointGrDatas(){
            let param = {
                action:0,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
            };
            this.cloading = true;
            console.log("GetEnePointGrDatas param",param);
            getEnePointGrList(param)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res.errorCode === '00'){
                    this.grList = res.data;
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('saveVirtialPointData',err);
            });
        },
        savaPointGr(){
              
            let param = {
                action:0,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                id:  this.form.id,
                name:this.form.name,
                parentId:this.form.parentId,
            };
            this.cloading = true;
            console.log("SavaPointGr param",param);
            saveEnePointGr(param)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res.errorCode === '00' || res.errorCode === '03'){
                   this.$message.success(this.$t('energy.enedata180'));
                   this.form.id = res.data.id,
                   this.form.name = res.data.name;
                   this.grId = [];
                   this.grId.push(res.data.id+'');
                   this.deleable = false;
                   this.getEnePointGrDatas();
                   
                } else {
                    this.$message.error(this.$t('energy.enedata181'));
                }

            })
            .catch((err) => {
                this.cloading = false;
                console.log('saveVirtialPointData',err);
            });
        },
        deletePointGr(){
            let param = {
                action:0,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                id:  this.form.id,
                name:this.form.name,
            };
            this.cloading = true;
            console.log("DELETE DATA",param);
            deleteEnePointGr(param)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res.errorCode === '00'|| res.errorCode === '05'){
                   this.$message.success(this.$t('energy.enedata182'));
                   this.grId = [];
                   this.form.id = 0,
                   this.form.name = '';
                   this.form.parentId = '';
                   this.isshow = false;
                   this.getEnePointGrDatas();
                } else {
                    this.$message.error(this.$t('energy.enedata183'));
                }

            })
            .catch((err) => {
                this.cloading = false;
                console.log('saveVirtialPointData',err);
            });
        },
  },
};
</script>
<style scoped>
    #enePointGR{
        height: 100%;
        width:100%;
        position: relative;
    }
    .info{
        width:100%;
        text-align:center;
        margin:5px;
        padding:5px;
        display: flex;
        justify-content:center;
    }
    .mg-add{
        margin-left: 10px;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        font-size: 24px;
        color: #7682ce;
        padding: 4px;
    }
    .mg-add:hover{
        color: #FFFFFF;
        background-color: #7682ce;
    }

    div /deep/.ant-modal-body{
        padding: 10px;
    }
</style>