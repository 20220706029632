<template>
  <div id="eneCalePointDetail" ref="eneCalePointDetail">
    <a-modal v-model="visible" :title="title"  :width="1024" :getContainer="() => this.$refs.eneCalePointDetail" @ok="onSubmit" >
        <div v-loading="loading" :element-loading-text="$t('alarm.a0')">
            <a-form-model ref="eneCalePointDetailForm" 
                :model="form" 
                :rules="rules"
                :label-col="labelCol" 
                :wrapper-col="wrapperCol" >
                <div class="base-box">
                    <a-row>
                        <a-col :span="6">
                            <!-- 运算点类型 -->
                            <a-form-model-item :label="$t('energy.enedata193')" ref="calcPointKind" prop="calcPointKind"  class="form-dis-item">
                                <a-select v-model="form.calcPointKind" :placeholder="$t('energy.enedata224')">
                                    <a-select-option
                                        v-for="(itme, index) in kindList"
                                        :key="index"
                                        :value="itme.no"
                                        >
                                        {{ itme.text }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <!-- 运算式 -->
                            <a-form-model-item :label="$t('energy.enedata198')" ref="calcFormulaType" prop="calcFormulaType" class="form-dis-item">
                                <a-select v-model="form.calcFormulaType" :placeholder="$t('energy.enedata225')" @change="calcFormulaTypeChange">
                                    <a-select-option
                                        v-for="(itme, index) in calcList"
                                        :key="index"
                                        :value="itme.no"
                                        >
                                        {{ itme.text }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <!-- 所属 -->
                            <a-form-model-item :label="$t('energy.enedata199')" ref="grs" prop="grs">
                                <a-cascader 
                                    v-model="form.grs" 
                                    :field-names="{ label: 'text', value: 'no', children: 'children' }" :options="grList" 
                                    change-on-select  :placeholder="$t('energy.enedata228')" />
                            </a-form-model-item>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-col :span="6">
                            <a-form-model-item label="TagPoint" class="form-dis-item">
                                <a-input  v-model="form.tagPoint" placeholder="" :readOnly="true"/>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item ref="name" prop="name" class="form-dis-item">
                                <span slot="label">
                                                {{$t('energy.enedata164')}}&nbsp;
                                    <a-tooltip :title='disallowed'>
                                    <a-icon type="question-circle-o" />
                                    </a-tooltip>
                                </span>
                                <a-input 
                                    v-model="form.name"
                                    @blur="() => {  $refs.name.onFieldBlur(); }"
                                    :placeholder="$t('energy.enedata222')"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <!-- 首页数据展示 -->
                            <a-form-model-item :label="$t('energy.enedata200')" prop="display">
                                <a-radio-group v-model="form.display">
                                    <a-radio value="1">
                                    {{$t('energy.enedata201')}}
                                    </a-radio>
                                    <a-radio value="0">
                                    {{$t('energy.enedata202')}}
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="6">
                            <!-- 单位名称 -->
                            <a-form-model-item ref="unit" prop="unit" class="form-dis-item">
                                <span slot="label">
                                                {{$t('energy.enedata203')}}&nbsp;
                                    <a-tooltip :title="$t('energy.enedata204')">
                                    <a-icon type="question-circle-o" />
                                    </a-tooltip>
                                </span>
                                <a-select v-model="form.unit" :placeholder="$t('energy.enedata205')">
                                    <!-- placeholder="请选择单位名称!" -->
                                    <a-select-option
                                        v-for="(itme, index) in unitList"
                                        :key="index"
                                        :value="itme.no"
                                    >
                                        {{ itme.text }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <!-- 自由单位 -->
                            <a-form-model-item ref="freeUnit" prop="freeUnit" class="form-dis-item">
                                <span slot="label">
                                               {{$t('energy.enedata206')}} &nbsp;
                                    <a-tooltip :title='disallowed'>
                                    <a-icon type="question-circle-o" />
                                    </a-tooltip>
                                </span>
                                <a-input 
                                    v-model="form.freeUnit"
                                    @blur="
                                    () => {
                                        $refs.freeUnit.onFieldBlur();
                                    }
                                    "
                                    :placeholder="$t('energy.enedata324')" :disabled="form.unit!='0'"
                                />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <!-- 小数点以下位数 -->
                            <a-form-model-item :label="$t('energy.enedata207')" ref="decimalPoint" prop="decimalPoint" class="form-dis-item">
                                <a-select v-model="form.decimalPoint" :placeholder="$t('energy.enedata227')">
                                    <a-select-option
                                        v-for="(itme, index) in decimalList"
                                        :key="index"
                                        :value="itme.no"
                                        >
                                        {{ itme.text }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="6">
                            <!-- 信号类型 -->
                            <a-form-model-item :label="$t('energy.enedata208')" ref="signalType" prop="signalType" class="form-dis-item">
                                <a-select v-model="form.signalType" :placeholder="$t('energy.enedata205')">
                                    <a-select-option
                                        v-for="(itme, index) in signalList"
                                        :key="index"
                                        :value="itme.no"
                                        >
                                        {{ itme.text }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                    </a-row>
                </div>
                <a-row>
                    <a-col :span="8" >
                        <div class="point-box">
                            <div style="padding:3px;border-bottom:1px solid #f5f5f5;">   
                                <!-- 设备列表 -->
                                {{$t('energy.enedata209')}}
                            </div>
                            <div style="display:flex;justify-content: space-between;padding:3px;">
                                <!-- 所属 -->
                                    <div style="width:50px;line-height:32px;">  {{$t('energy.enedata199')}}</div>
                                    <a-cascader   v-model="form.qrygrs" 
                                        :field-names="{ label: 'text', value: 'no', children: 'children' }" :options="grList" 
                                        change-on-select  :placeholder="$t('energy.enedata228')" @change="pointGrChange" />
                            </div>
                            <div>
                                <ene-point-table :data="pointList" :selectedRowKeys="selRowKeys" :fatherChangeMethod="handlerPointOpt" :Yscroll="chcPointYscroll"  :checkType="pcheckType"></ene-point-table>
                            </div>
                        </div>
                       
                    </a-col>
                    <a-col :span="16" v-if="form.calcFormulaType=='1'">
                        <div class="formula-box">
                            <div style="display:flex;justify-content: space-between;padding:3px;border-bottom:1px solid #f5f5f5;">   
                                <div>
                                    <!-- 加减运算设置 -->
                                      {{$t('energy.enedata210')}}
                                </div>
                                <!-- 公式 -->
                                <div>  {{$t('energy.enedata188')}}：{{form.calcFormula}}</div>
                            </div>
                            <a-row>
                                <a-col :span="12">
                                    <div style="display:flex;justify-content: space-between;padding:3px;">
                                        <div style="margin-left:5px;line-height:32px;">
                                            <!-- 加法选择设备列表 -->
                                              {{$t('energy.enedata211')}}
                                        </div>
                                        <div>
                                            <a-button type="primary" @click="selectPoints('ADD')" :disabled="selRowKeys.length==0"> {{$t('energy.enedata212')}}</a-button>
                                            &nbsp;
                                            <a-button type="primary" @click="removePoints('ADD')" :disabled="selAdditionRowKeys.length==0"> {{$t('energy.enedata213')}}</a-button>
                                        </div>
                                    </div>
                                    <div>
                                        <ene-point-table :data="additionPointList" :selectedRowKeys="selAdditionRowKeys" :fatherChangeMethod="handlerReAdditionPointOpt" :Yscroll="chcPointYscroll" :checkType="checkType"></ene-point-table>
                                    </div>
                                </a-col>
                                <a-col :span="12" >
                                    <div style="display:flex;justify-content: space-between;padding:3px;">
                                        <div style="margin-left:5px;line-height:32px;">
                                            <!-- 减法选择设备列表 -->
                                            {{$t('energy.enedata214')}}
                                        </div>
                                        <div>
                                            <a-button type="primary" @click="selectPoints('SUB')" :disabled="selRowKeys.length==0"> {{$t('energy.enedata212')}}</a-button>
                                            &nbsp;
                                            <a-button type="primary" @click="removePoints('SUB')" :disabled="selSubtractionRowKeys.length==0"> {{$t('energy.enedata213')}}</a-button>
                                        </div>
                                    </div>
                                    <div style="margin-left:5px;">
                                        <ene-point-table :data="subtractionPointList" :selectedRowKeys="selSubtractionRowKeys" :fatherChangeMethod="handlerReSubtractionPointOpt" :Yscroll="chcPointYscroll" :checkType="checkType"></ene-point-table>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                    </a-col>
                    <a-col :span="16"  v-else-if="form.calcFormulaType=='2'">
                        <div>   
                            <!-- 一般运算设置 -->
                              {{$t('energy.enedata215')}}
                        </div>
                        <div>
                            <a-form-model-item ref="calcFormula" prop="calcFormula" class="form-dis-item" v-bind="tailLayout">
                                <span slot="label">
                                                  {{$t('energy.enedata188')}}&nbsp;
                                    <!-- <a-tooltip :title='disallowed'>
                                    <a-icon type="question-circle-o" />
                                    </a-tooltip> -->
                                </span>
                                <a-input 
                                    v-model="form.calcFormula"
                                    @blur="
                                    () => {
                                        $refs.calcFormula.onFieldBlur();
                                    }
                                    "
                                    :placeholder="$t('energy.enedata323')"
                                />
                            </a-form-model-item>
                        </div>
                        <div class="normal-box">
                            <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">A:</div>
                                 <a-input-search v-model="normal.A.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')" @search="selectPoint('A')" >
                                     <a-button type="primary" slot="enterButton" v-if="normal.A.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">B:</div>
                                 <a-input-search v-model="normal.B.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')" @search="selectPoint('B')" >
                                     <a-button type="primary" slot="enterButton" v-if="normal.B.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">C:</div>
                                 <a-input-search v-model="normal.C.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('C')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.C.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">D:</div>
                                 <a-input-search v-model="normal.D.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('D')">
                                    <a-button type="primary" slot="enterButton" v-if="normal.D.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">E:</div>
                                 <a-input-search v-model="normal.E.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('E')">
                                    <a-button type="primary" slot="enterButton" v-if="normal.E.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">F:</div>
                                 <a-input-search v-model="normal.F.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('F')">
                                    <a-button type="primary" slot="enterButton" v-if="normal.F.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">G:</div>
                                 <a-input-search v-model="normal.G.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('G')">
                                    <a-button type="primary" slot="enterButton" v-if="normal.G.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">H:</div>
                                 <a-input-search v-model="normal.H.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('H')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.H.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">I:</div>
                                 <a-input-search v-model="normal.I.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('I')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.I.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">J:</div>
                                 <a-input-search v-model="normal.J.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('J')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.J.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">K:</div>
                                 <a-input-search v-model="normal.K.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('K')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.K.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">L:</div>
                                 <a-input-search v-model="normal.L.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('L')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.L.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">M:</div>
                                 <a-input-search v-model="normal.M.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('M')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.M.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">N:</div>
                                 <a-input-search v-model="normal.N.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('N')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.N.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">O:</div>
                                 <a-input-search v-model="normal.O.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('O')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.O.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">P:</div>
                                 <a-input-search v-model="normal.P.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('P')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.P.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">Q:</div>
                                 <a-input-search v-model="normal.Q.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('Q')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.Q.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">R:</div>
                                 <a-input-search v-model="normal.R.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('R')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.R.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">S:</div>
                                 <a-input-search v-model="normal.S.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('S')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.S.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">T:</div>
                                 <a-input-search v-model="normal.T.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('T')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.T.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">U:</div>
                                 <a-input-search v-model="normal.U.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('U')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.U.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">V:</div>
                                 <a-input-search v-model="normal.V.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('V')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.V.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">W:</div>
                                 <a-input-search v-model="normal.W.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('W')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.W.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">X:</div>
                                 <a-input-search v-model="normal.X.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('X')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.X.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">Y:</div>
                                 <a-input-search v-model="normal.Y.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('Y')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.Y.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                             <div class="normal-point-dis" >
                                <div style="font-size:16px;line-height:32px;">Z:</div>
                                 <a-input-search v-model="normal.Z.pointName"  style="width: 280px" :placeholder="$t('energy.enedata217')"  @search="selectPoint('Z')" >
                                    <a-button type="primary" slot="enterButton" v-if="normal.Z.pointName===''">
                                         {{$t('energy.enedata212')}}
                                    </a-button>
                                    <a-button type="primary" slot="enterButton" v-else>
                                         {{$t('energy.enedata213')}}
                                    </a-button>
                                 </a-input-search>
                            </div>
                        </div>
                    </a-col>
                </a-row>
               
            </a-form-model>
        </div>
    </a-modal>
  </div>
</template>
<script>
//import {saveCalcPointData} from "../../../api/energy";
import EnePointTable from "../components/measurePoint/eneCalcPointTable.vue";
import { getCalcPointData  , saveCalcPointData} from "../../../api/energy";
// JavaScript版replaceAll
function replaceAll(expression, org, dest){
	return expression.split(org).join(dest);
}
function chkFormula(val) {
	let suuzi = val;
	var suuzi_arr;
	suuzi = replaceAll(suuzi,"+", "_");
	suuzi = replaceAll(suuzi,"-", "_");
	suuzi = replaceAll(suuzi,"*", "_");
	suuzi = replaceAll(suuzi,"/", "_");
	suuzi = replaceAll(suuzi,"^", "_");
	suuzi = replaceAll(suuzi,"(", "_");
	suuzi = replaceAll(suuzi,")", "_");
	for(var i=0;i<10;i++){
		suuzi = replaceAll(suuzi,"__", "_");
	}
	suuzi_arr = suuzi.split("_");
	//小数点桁数のチェック
	for(var i=0;i<suuzi_arr.length;i++){
		var suuzi2 = suuzi_arr[i].split(".");
		if(suuzi2.length == 2){
			if(!isNaN(Number(suuzi_arr[i]))){//数値にならないものはエラーとする。
				var suuzi3 = suuzi_arr[i].split(".");
				if(suuzi3.length == 2){
					if(suuzi3[1].length > 6){
						//小数点以下6桁以上の場合
						return false;
					}
				}
			}else{
				return false;
			}
		}else{
			if(suuzi2.length != 1){
				//不自然にピリオドがある場合
				return false;
			}
		}
	}
	//べき乗のチェック
	suuzi = val;
	suuzi = replaceAll(suuzi,"+", "_");
	suuzi = replaceAll(suuzi,"-", "_");
	suuzi = replaceAll(suuzi,"*", "_");
	suuzi = replaceAll(suuzi,"/", "_");
	suuzi = replaceAll(suuzi,"(", "_");
	suuzi = replaceAll(suuzi,")", "_");
	for(var i=0;i<20;i++){
		suuzi = replaceAll(suuzi,"__", "_");
	}
	if(suuzi.lastIndexOf("^") != -1){
		suuzi_arr = suuzi.split("_");
		for(var i=0;i<suuzi_arr.length;i++){
			suuzi2 = suuzi_arr[i].split("^");
			for(var j=1;j<suuzi2.length;j++){
				if(suuzi2[j].lastIndexOf(".") != -1){
					return false;//小数の可能性があるのでエラーとする。
				}
				if(!isNaN(Number(suuzi2[j]))){//数値にならないものはエラーとする。
					if(suuzi2[j].length>3){//整数3桁以上はエラーとする。
						return false;
					}
				}else{
					return false;
				}
			}
		}
    }
    
    return true;
}
export default {
  name: 'eneCalePointDetail',
  props: {
    title: {
      type: String,
      default: '运算点设置',
    },
    id: {
      type: Number,
      default:0,
    }
  },
  data() {
      let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata218')));
        } else {
        callback();
      }
    };

     let normalString = (rule, value, callback) => {
       let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
 
        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata218')));
        } else {
            callback();
      }
    };
    //enedata609
    let checkFormula = (rule, value, callback) => {
        if(value.match(/[^0-9A-Z\()\+\-\/\.\*\^]/)) {
            callback(new Error(this.$t('energy.enedata609')));
        } else if(!chkFormula(value)){
            callback(new Error(this.$t('energy.enedata609')));
        } else {
            callback();
        }
    };
    let normal = this.getDefaultData();
    return {
        loading : false,
        visible : false,
        form:{id: 0,
            calcFormulaType: "1",
            calcPointKind: "0",
            grs:[],
            gr1: "",
            gr2: "",
            gr3: "",
            tagPoint: "",
            name: "",
            unit: "0",
            freeUnit: "",
            calcFormula: "",
            decimalPoint: "-1",
            signalType: '1',
            display: "0",
            qrygrs:[],
        },
        labelCol: {
            // xs: { span: 24 },
            // sm: { span: 8 },
        },
        wrapperCol: {
            // xs: { span: 24 },
            // sm: { span: 16 },
        },
        tailLayout:{
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },  
        },
        rules: {
            name: [
                { required: true, message: this.$t('energy.enedata222'), trigger: 'blur' },
                { min: 1, max: 30, message: this.$t('energy.enedata219'), trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            freeUnit: [
                { min: 0, max: 30, message: this.$t('energy.enedata220'), trigger: 'blur' },
                { validator:normalString, trigger: 'blur' },
            ],
            calcFormula: [
                { required: true, message: this.$t('energy.enedata223'), trigger: 'blur' },
                { min: 0, max: 200, message: this.$t('energy.enedata221'), trigger: 'blur' },
                { validator:checkFormula, trigger: 'blur' },
            ],
            
            calcPointKind: [{ required: true, message:  this.$t('energy.enedata224'), trigger: 'change' }],
            calcFormulaType: [{ required: true, message:  this.$t('energy.enedata225'), trigger: 'change' }],
            unit: [{ required: true, message:  this.$t('energy.enedata205'), trigger: 'change' }],
            decimalPoint: [{ required: true, message:  this.$t('energy.enedata227'), trigger: 'change' }],
            grs:[{
                type: 'array',
                required: true,
                message:  this.$t('energy.enedata228'),
                trigger: 'change',
                 },
            ],
        }, 
        kindList:[],
        calcList:[],
        grList:[],
        unitList:[],
        decimalList:[],
        signalList:[],
        pointList:[],
        additionPointList:[],
        subtractionPointList:[],
        chcPointYscroll:{y:200,x:360},
        normal:normal,
        pcheckType:'checkbox',//radio
        checkType:'checkbox',
        selRowKeys:[],
        selAdditionRowKeys:[],
        selSubtractionRowKeys:[],
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
    };
  },
  mounted(){
    //this.loadCalcPointData();
  },
  watch:{
    "$parent.detail.visible":{
      handler(val){
          if(val){              
                this.resetForm();
                this.form.tagPoint = '';
                this.form.qrygrs = [];
                this.pointList = [];
                this.additionPointList =	[];
                this.subtractionPointList =	[];
                this.kindList=[];
                this.calcList=[];
                this.grList=[];
                this.unitList=[];
                this.decimalList=[];
                this.signalList=[];
                this.selRowKeys=[];
                this.selAdditionRowKeys=[];
                this.selSubtractionRowKeys=[];
                this.normal = this.getDefaultData();
                this.visible = this.$parent.detail.visible;
                this.loadCalcPointData(0);
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          if(!val){
              this.$parent.detail.visible =  this.visible;
          }
      },
      deep:true,
      immediate:true,
    },
  },
  methods: {
        loadCalcPointData(action,gr1Id,gr2Id,gr3Id){
          let param = null;
            if(action==0){
                console.log('loadCalcPointData id',this.id);
                param = {
                    action:action,
                    clientId: this.$store.getters.clientId,
                    siteId: this.$route.query.id,
                    sitegrId: this.$store.getters.sitegrId,
                    tenantId: 0,
                    id: this.id,
                };
            }else{
                console.log('loadCalcPointData grids',gr1Id,gr2Id,gr3Id);
                param = {
                    action:1,
                    clientId: this.$store.getters.clientId,
                    siteId: this.$route.query.id,
                    sitegrId: this.$store.getters.sitegrId,
                    tenantId: 0,
                    gr1Id:gr1Id,
                    gr2Id:gr2Id,
                    gr3Id:gr3Id,
                };
            }
            console.log("LoadCalcPointData DATA",param);
            this.loading = true;
            getCalcPointData(param)
            .then((res) => {
                console.log("LoadData Result",res.data);
                if(res.errorCode === '00'){
                    if(action==0){
                        if(res.data.calcPoint){
                            this.form.id=                   res.data.calcPoint.id						;
                            this.form.calcFormulaType=      res.data.calcPoint.calcFormulaType+''          ;
                            this.form.calcPointKind=        res.data.calcPoint.calcPointKind+''                         ;
                            this.form.gr1=                  res.data.calcPoint.gr1                         ;
                            this.form.gr2=                  res.data.calcPoint.gr2                         ;
                            this.form.gr3=                  res.data.calcPoint.gr3                         ;
                            this.form.tagPoint=             res.data.calcPoint.tagPoint                         ;
                            this.form.name=                 res.data.calcPoint.name                         ;

                            this.form.unit=                 res.data.calcPoint.unit+''                         ;
                            if( res.data.calcPoint.unit==0){
                                this.form.freeUnit=             res.data.calcPoint.freeUnit	                         ;
                            }
                            
                            this.form.calcFormula=          res.data.calcPoint.calcFormula              ;
                            this.form.decimalPoint=         res.data.calcPoint.decimalPoint+''                         ;
                            this.form.signalType=           res.data.calcPoint.signalType+''                         ;
                            this.form.display=              res.data.calcPoint.display?'1':'0'	                         ;
                            this.form.grs = new Array();
                            if(this.form.gr1>0){
                                this.form.grs.push(this.form.gr1+'');
                            }
                            if(this.form.gr2>0){
                                this.form.grs.push(this.form.gr2+'');
                            }
                            if(this.form.gr3>0){
                                this.form.grs.push(this.form.gr3+'');
                            }
                            this.additionPointList		=	res.data.calcPoint.additionPointList;
                            this.subtractionPointList   =	res.data.calcPoint.subtractionPointList;
                            if(res.data.calcPoint.generalFormulaPoint){
                                this.setNormalPointData(res.data.calcPoint.generalFormulaPoint)
                            }
                            //this.generalFormulaPoint    =	res.data.calcPoint.generalFormulaPoint;
                        } else {
                            this.form = {id: 0,
                                calcFormulaType: "1",
                                calcPointKind: "0",
                                grs:[],
                                gr1: "",
                                gr2: "",
                                gr3: "",
                                tagPoint: "",
                                name: "",
                                unit: "0",
                                freeUnit: "",
                                calcFormula: "",
                                decimalPoint: "-1",
                                signalType: '1',
                                display: "0",
                                qrygrs:[],
                            }
                        }
                        if(res.data.dictionary){
                            this.kindList=   res.data.dictionary.calcPointKindList;
                            this.calcList=   res.data.dictionary.calcFormulaTypeList;
                            this.grList=   res.data.dictionary.registerPointGrs;
                            this.unitList=   res.data.dictionary.unitList;
                            this.decimalList=   res.data.dictionary.decimalList;
                            this.signalList=   res.data.dictionary.pointSignalList;
                        }

                        this.pcheckType = this.form.calcFormulaType==='1'?'checkbox':'radio';
                    }else{
                        if(res.data.pointList){
                            this.pointList=  res.data.pointList;
                        }
                    }

                } else {
                   this.$message.error('数据加载失败');
                }
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log('saveVirtialPointData',err);
            });
        },
        onSubmit() {
            this.$refs.eneCalePointDetailForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title: this.$t('energy.enedata229'),// '确认要保存所设置的运算点信息吗？',
                        centered: true,
                        onOk: () => {
                            this.saveCalcPoint();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            if(this.$refs.eneCalePointDetailForm){
                this.$refs.eneCalePointDetailForm.resetFields();
            }
        },
        createGeneralFormulaPoint(normal){
            let generalFormulaPoint = {};
            if(normal){
                if(normal.A && normal.A.id > 0){
                    generalFormulaPoint.a = normal.A
                }                         
                if(normal.B&& normal.B.id > 0){              
                    generalFormulaPoint.b = normal.B
                }                         
                if(normal.C&& normal.C.id > 0){              
                    generalFormulaPoint.c = normal.C
                }                         
                if(normal.D&& normal.D.id > 0){              
                    generalFormulaPoint.d = normal.D
                }                         
                if(normal.E&& normal.E.id > 0){              
                    generalFormulaPoint.e = normal.E
                }                         
                if(normal.F&& normal.F.id > 0){              
                    generalFormulaPoint.f = normal.F
                }                         
                if(normal.G&& normal.G.id > 0){              
                    generalFormulaPoint.g = normal.G
                }                         
                if(normal.H&& normal.H.id > 0){              
                    generalFormulaPoint.h = normal.H
                }                         
                if(normal.I&& normal.I.id > 0){              
                    generalFormulaPoint.i = normal.I
                }                         
                if(normal.J&& normal.J.id > 0){              
                    generalFormulaPoint.j = normal.J
                }                         
                if(normal.K&& normal.K.id > 0){              
                    generalFormulaPoint.k = normal.K
                }                         
                if(normal.L&& normal.L.id > 0){              
                    generalFormulaPoint.l = normal.L
                }                         
                if(normal.M&& normal.M.id > 0){              
                    generalFormulaPoint.m = normal.M
                }                         
                if(normal.N&& normal.N.id > 0){              
                    generalFormulaPoint.n = normal.N
                }                         
                if(normal.O&& normal.O.id > 0){              
                    generalFormulaPoint.o = normal.O
                }                         
                if(normal.P&& normal.P.id > 0){              
                    generalFormulaPoint.p = normal.P
                }                         
                if(normal.Q&& normal.Q.id > 0){              
                    generalFormulaPoint.q = normal.Q
                }                         
                if(normal.R&& normal.R.id > 0){              
                    generalFormulaPoint.r = normal.R
                }                         
                if(normal.S&& normal.S.id > 0){              
                    generalFormulaPoint.s = normal.S
                }                         
                if(normal.T&& normal.T.id > 0){              
                    generalFormulaPoint.t = normal.T
                }                         
                if(normal.U&& normal.U.id > 0){              
                    generalFormulaPoint.u = normal.U
                }                         
                if(normal.V&& normal.V.id > 0){              
                    generalFormulaPoint.v = normal.V
                }                         
                if(normal.W&& normal.W.id > 0){              
                    generalFormulaPoint.w = normal.W
                }                         
                if(normal.X&& normal.X.id > 0){              
                    generalFormulaPoint.x = normal.X
                }                         
                if(normal.Y&& normal.Y.id > 0){              
                    generalFormulaPoint.y = normal.Y
                }                         
                if(normal.Z&& normal.Z.id > 0){              
                    generalFormulaPoint.z = normal.Z
                }
            }

            return generalFormulaPoint;
        },
        saveCalcPoint(){
            let gr1 = this.form.grs.length>0?this.form.grs[0]:0;
            let gr2 = this.form.grs.length>1?this.form.grs[1]:0;
            let gr3 = this.form.grs.length>2?this.form.grs[2]:0;
            let generalFormulaPoint = this.createGeneralFormulaPoint(this.normal);
            let param = {
                action:0,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                id: this.form.id,
                calcFormulaType: this.form.calcFormulaType,
                calcPointKind: this.form.calcPointKind,
                gr1: gr1,
                gr2: gr2,
                gr3: gr3,
                tagPoint: this.form.tagPoint,
                name: this.form.name,
                unit: this.form.unit,
                freeUnit: this.form.freeUnit,
                calcFormula: this.form.calcFormula,
                decimalPoint: this.form.decimalPoint,
                signalType: this.form.signalType,
                display: this.form.display==='1'?true:false,
                additionPointList:this.additionPointList,
                subtractionPointList:this.subtractionPointList,
                generalFormulaPoint:generalFormulaPoint,
            };
            this.loading = true;
            console.log("SaveCalcPoint DATA PARAM>",param);
            saveCalcPointData(param)
            .then((res) => {
                console.log("SaveCalcPoint Data Result",res.data);
                if(res.errorCode === '00' || res.errorCode === '03'){
                   this.$message.success(this.$t('energy.enedata180'));
                   this.visible = false;
                   this.$parent.search();
                } else {
                    // this.$message.error(this.$t('energy.enedata181'));
                    this.$message.error(res.msg);

                }

                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log('saveCalcPointData',err);
            });
        },
        pointGrChange(e){
            console.log('pointGrChange',e);
            let gr1 = e.length>0?e[0]:0;
            let gr2 = e.length>1?e[1]:0;
            let gr3 = e.length>2?e[2]:0;
            this.loadCalcPointData(1,gr1,gr2,gr3);

        },
        handlerPointOpt(e){
             console.log('handlerPointOpt',e);
             this.selRowKeys = e;
        },
        handlerReAdditionPointOpt(e){
            this.selAdditionRowKeys = e;
        },
        handlerReSubtractionPointOpt(e){
            this.selSubtractionRowKeys = e;
        },
        calcFormulaTypeChange(e){
            if(e==='1'){
                this.pcheckType = 'checkbox';
            }else{
                this.pcheckType = 'radio';
            }
        },
        setNormalPointData(generalFormulaPoint){
            if(generalFormulaPoint){
                if(generalFormulaPoint.a){
                    this.normal.A = generalFormulaPoint.a
                }
                if(generalFormulaPoint.b){
                    this.normal.B = generalFormulaPoint.b
                }
                if(generalFormulaPoint.c){
                    this.normal.C = generalFormulaPoint.c
                }
                if(generalFormulaPoint.d){
                    this.normal.D = generalFormulaPoint.d
                }
                if(generalFormulaPoint.e){
                    this.normal.E = generalFormulaPoint.e
                }
                if(generalFormulaPoint.f){
                    this.normal.F = generalFormulaPoint.f
                }
                if(generalFormulaPoint.g){
                    this.normal.G = generalFormulaPoint.g
                }
                if(generalFormulaPoint.h){
                    this.normal.H = generalFormulaPoint.h
                }
                if(generalFormulaPoint.i){
                    this.normal.I = generalFormulaPoint.i
                }
                if(generalFormulaPoint.j){
                    this.normal.J = generalFormulaPoint.j
                }
                if(generalFormulaPoint.k){
                    this.normal.K = generalFormulaPoint.k
                }
                if(generalFormulaPoint.l){
                    this.normal.L = generalFormulaPoint.l
                }
                if(generalFormulaPoint.m){
                    this.normal.M = generalFormulaPoint.m
                }
                if(generalFormulaPoint.n){
                    this.normal.N = generalFormulaPoint.n
                }
                if(generalFormulaPoint.o){
                    this.normal.O = generalFormulaPoint.o
                }
                if(generalFormulaPoint.p){
                    this.normal.P = generalFormulaPoint.p
                }
                if(generalFormulaPoint.q){
                    this.normal.Q = generalFormulaPoint.q
                }
                if(generalFormulaPoint.r){
                    this.normal.R = generalFormulaPoint.r
                }
                if(generalFormulaPoint.s){
                    this.normal.S = generalFormulaPoint.s
                }
                if(generalFormulaPoint.t){
                    this.normal.T = generalFormulaPoint.t
                }
                if(generalFormulaPoint.u){
                    this.normal.U = generalFormulaPoint.u
                }
                if(generalFormulaPoint.v){
                    this.normal.V = generalFormulaPoint.v
                }
                if(generalFormulaPoint.w){
                    this.normal.W = generalFormulaPoint.w
                }
                if(generalFormulaPoint.x){
                    this.normal.X = generalFormulaPoint.x
                }
                if(generalFormulaPoint.y){
                    this.normal.Y = generalFormulaPoint.y
                }
                if(generalFormulaPoint.z){
                    this.normal.Z = generalFormulaPoint.z
                }

            }
        },
        getDefaultData(){
            return {
                A:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                B:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                C:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                D:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                E:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                F:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                G:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                H:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                I:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                J:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                K:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                L:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                M:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                N:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                O:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                P:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                Q:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                R:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                S:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                T:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                U:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                V:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                W:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                X:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                Y:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
                Z:{id: 0, pointType: 0,  pointNo: '', pointName: '',},
            };
        },
        getSelectPoint(){
            if( this.selRowKeys.length<=0){
                this.$message.error(this.$t('energy.enedata230'));
                return null;
            }
            let e = this.selRowKeys.length>0? this.selRowKeys[0]:0;
            console.log('selectPoint',e);
            const newData = [...this.pointList];
            const target = newData.filter(item => e === item.id)[0];
            return  target;
        },
        selectPoint(kind){
            
            if(kind==="A"){
                if(this.normal.A.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.A=point;
                    }
                }else{
                    this.normal.A={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="B"){
                if(this.normal.B.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.B=point;
                    }
                }else{
                    this.normal.B={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="C"){
                if(this.normal.C.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.C=point;
                    }
                }else{
                    this.normal.C={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="D"){
                if(this.normal.D.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.D=point;
                    }
                }else{
                    this.normal.D={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="E"){
                if(this.normal.E.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.E=point;
                    }
                }else{
                    this.normal.E={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="F"){
                if(this.normal.F.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.F=point;
                    }
                }else{
                    this.normal.F={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="G"){
                if(this.normal.G.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.G=point;
                    }
                }else{
                    this.normal.G={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="H"){
                if(this.normal.H.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.H=point;
                    }
                }else{
                    this.normal.H={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="I"){
                if(this.normal.I.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.I=point;
                    }
                }else{
                    this.normal.I={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="J"){
                if(this.normal.J.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.J=point;
                    }
                }else{
                    this.normal.J={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="K"){
                if(this.normal.K.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.K=point;
                    }
                }else{
                    this.normal.K={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="L"){
                if(this.normal.L.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.L=point;
                    }
                }else{
                    this.normal.L={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="M"){
                if(this.normal.M.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.M=point;
                    }
                }else{
                    this.normal.M={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="N"){
                if(this.normal.N.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.N=point;
                    }
                }else{
                    this.normal.N={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="O"){
                if(this.normal.O.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.O=point;
                    }
                }else{
                    this.normal.O={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="P"){
                if(this.normal.P.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.P=point;
                    }
                }else{
                    this.normal.P={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="Q"){
                if(this.normal.Q.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.Q=point;
                    }
                }else{
                    this.normal.Q={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="R"){
                if(this.normal.R.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.R=point;
                    }
                }else{
                    this.normal.R={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="S"){
                if(this.normal.S.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.S=point;
                    }
                }else{
                    this.normal.S={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="T"){
                if(this.normal.T.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.T=point;
                    }
                }else{
                    this.normal.T={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="U"){
                if(this.normal.U.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.U=point;
                    }
                }else{
                    this.normal.U={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="V"){
                if(this.normal.V.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.V=point;
                    }
                }else{
                    this.normal.V={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="W"){
                if(this.normal.W.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.W=point;
                    }
                }else{
                    this.normal.W={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="X"){
                if(this.normal.X.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.X=point;
                    }
                }else{
                    this.normal.X={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="Y"){
                if(this.normal.Y.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.Y=point;
                    }
                }else{
                    this.normal.Y={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
            if(kind==="Z"){
                if(this.normal.Z.pointName===''){ 
                    let point = this.getSelectPoint();
                    if(point!=null){
                        console.log('selectPoint',point);
                        this.normal.Z=point;
                    }
                }else{
                    this.normal.Z={id: 0, pointType: 0,  pointNo: '', pointName: '',};
                }
            }
        },
        selectPoints(kind){
            if( this.selRowKeys.length<=0){
                this.$message.error(this.$t('energy.enedata230'));
                return null;
            }
            let points = new Array();
            const newData = [...this.pointList];
            if('ADD'===kind){
                this.selRowKeys.forEach(e => {
                    const target = newData.filter(item => e === item.id)[0];
                    if(target){
                        let bol = false;
                        if(this.additionPointList.length>0){
                            this.additionPointList.forEach(e => {
                                if(e.id==target.id){
                                bol = true;
                                }
                            });
                        }   
                        if(!bol){
                            points.push(target);
                        }
                    }
                });
                this.additionPointList = [...this.additionPointList,...points];
            }else{
                 this.selRowKeys.forEach(e => {
                    const target = newData.filter(item => e === item.id)[0];
                    if(target){
                        let bol = false;
                        if(this.subtractionPointList.length>0){
                            this.subtractionPointList.forEach(e => {
                                if(e.id==target.id){
                                bol = true;
                                }
                            });
                        }   
                        if(!bol){
                            points.push(target);
                        }
                    }
                    
                });
                this.subtractionPointList = [...this.subtractionPointList,...points];
                console.log('subtractionPointList',this.subtractionPointList);
            }

            this.selRowKeys = [];
        },
        removePoints(kind){
            if('ADD'===kind){
                let points = new Array();
                this.additionPointList.forEach(item => {
                    let bol = true;
                    this.selAdditionRowKeys.forEach(e => {
                        if(item.id===e){
                            bol = false;
                        }
                    });
                    if(bol){
                        points.push(item);
                    }
                });
                this.additionPointList = points;
                this.selAdditionRowKeys = [];
            }else{
                let points = new Array();
                this.subtractionPointList.forEach(item => {
                    let bol = true;
                    this.selSubtractionRowKeys.forEach(e => {
                        if(item.id===e){
                            bol = false;
                        }
                    });
                    if(bol){
                        points.push(item);
                    }
                });
                this.subtractionPointList = points;
                this.selSubtractionRowKeys = [];
            }
        },
    },
   components: {
    'ene-point-table':EnePointTable,
  },
};
</script>
<style scoped>
    #eneCalePointDetail{
        height: 100%;
        width:100%;
        position: relative;  
    }
    .base-box{
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        padding: 3px;
        margin-bottom: 2px;
    }
    .point-box{
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        padding: 3px;
        margin-top: 1px;
    }

    .formula-box{
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        padding: 3px;
        margin-top: 1px;
    }
    .normal-box{
        height:200px; 
        overflow: auto;
        border: 1px solid #f5f5f5;
    }
    .normal-point-dis{float: left; width: 300px;margin: 3px;display:flex;justify-content: space-between;}
    .normal-point-dis::after{clear: both;}
    .form-dis-item{margin-right:3px ;}
</style>